<template>
  <div class="bg">
    <div class="margin">
      <div class="text-right desktop">
        <b-img width="240" class="mt-5" src="./img/logo-preta.png"></b-img>
      </div>
      <div class="text-left ml-3 mb-5 desktop">
        <a href="https://seguro.bikeregistrada.com.br/" class="underline">Ir para a página inicial do site</a>
      </div>
      <div class="text-center pt-3 mobile">
        <a href="https://seguro.bikeregistrada.com.br/" class="underline">Ir para a página inicial do site</a>
      </div>

      <div class="row mr-0 ml-0">
        <div class="col-sm-12 col-md-6">

          <h4 class="title-thanks mt-4 mb-4 bold desktop">Pagamento feito com sucesso!
          </h4>

          <h5 class="title-thanks mt-4 bold mobile text-left">Pagamento feito com sucesso!
          </h5>

          <h6 class="gray mt-4 mb-5 bold desktop">A partir de agora, você já conta com a cobertura do seguro provisório.
            Mas
            atenção, ainda restam algumas etapas essenciais para a emissão da sua apólice e a garantia total da sua
            proteção.
          </h6>

          <h6 class="gray mt-3 mobile f-14 line-height">A partir de agora, você já conta com a cobertura do seguro provisório.
            Mas
            atenção, ainda restam algumas etapas essenciais para a emissão da sua apólice e a garantia total da sua
            proteção.
          </h6>

          <div v-if="!seguro.possuiConta" class="d-flex pt-5 desktop">
            <img src="/img/chave-senha.svg" width="41px" />
            <span class="gray self-center ml-3 bold">É essencial criar a senha para continuar com a contratação do
              seguro no
              aplicativo. Crie sua senha clicando no botão abaixo para continuar a contratação do seguro no App Bike
              Registrada.
            </span>
          </div>
          <div v-if="!seguro.possuiConta" class="d-flex pt-2 mobile">
            <img src="/img/chave-senha.svg" width="41px" />
            <span class="gray self-center ml-3 font-small bold">É essencial criar a senha para continuar com a
              contratação do
              seguro no
              aplicativo. Crie sua senha clicando no botão abaixo para continuar a contratação do seguro no App Bike
              Registrada.
            </span>
          </div>

          <div v-if="!seguro.possuiConta" class="d-flex mt-4">
            <img src="/img/smartphone.svg" width="41px" />
            <span class="gray self-center ml-3 font-small bold">Após criar a sua senha, baixe o App Bike Registrada para
              finalizar a
              contratação do seu seguro e preencher os dados para emissão da apólice.
            </span>
          </div>

          <div v-if="seguro.possuiConta" class="d-flex mt-4">
            <img src="/img/smartphone.svg" width="41px" />
            <span class="gray self-center ml-3 font-small bold">Baixe o App Bike Registrada para finalizar a contratação
              do seu seguro e preencher os dados para emissão da apólice.
            </span>
          </div>
          <div class="text-center" v-if="seguro.possuiConta">
            <div class="d-flex mt-5 justify-center desktop">
              <img class="pointer" @click.prevent="redirectIos" src="/img/ios.svg">
              <img class="ml-3 pointer" @click.prevent="redirectPlay" src="/img/play.svg">
            </div>

            <button class="btn confirm mobile mt-5" @click="redirectToAppStore">
              <span class="text">Baixar App Bike Registrada</span><img class="ml-2" src="img/arrow-right.svg" />
            </button>
          </div>
          <div v-else>
            <div class="d-flex mt-5 desktop">
              <button class="continue-btn ml-3" @click="showModalSenha = true"><span>Criar senha de acesso
                  agora</span><img class="ml-2" src="img/arrow-right.svg" /></button>
            </div>
            <div class="mt-5 mb-5 mobile">
              <button class="continue-btn" @click="showModalSenha = true"><span>Criar senha de acesso
                  agora</span><img class="ml-2" src="img/arrow-right.svg" /></button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <ModalCriarSenha :show="showModalSenha" @hide="showModalSenha = false; showModalSucessoCheckout = false"
      @sucesso="showModalSucessoCheckout = true; showModalSenha = false;" :seguro="seguro" />
    <ModalSucessoCheckout :show="showModalSucessoCheckout" @hide="showModalSucessoCheckout = false" />
  </div>
</template>
<script>

import axios from "axios";
import Swal from "sweetalert2";
import config from "../config";
import captureError from "../helpers/captureError";
import gtmError from "../helpers/gtmError";

export default {
  components: {
    ModalCriarSenha: () =>
      import("../components/Modal/ModalCriarSenha.vue"),
    ModalSucessoCheckout: () =>
      import("../components/Modal/ModalSucessoCheckout.vue"),
  },

  data() {
    return {
      config,
      loadingGeral: false,
      seguro: {},
      showModalSenha: false,
      showModalSucessoCheckout: false,
    };
  },

  async created() {
    var strDadosLocalStorage = sessionStorage.getItem("dadosSimulacao");
    localStorage.removeItem("idSeguro");
    localStorage.removeItem("dadosSimulacao");
    if (!strDadosLocalStorage) {
      this.$router.replace("/login");
    }
    localStorage.removeItem("UserInfo");

    let dadosCotacao = JSON.parse(strDadosLocalStorage) || {};
    var idSeguro = dadosCotacao.id;
    dadosCotacao.passoCheckout == false;
    var result = await this.popularInformacoes(idSeguro);

    if (!result) {
      //Redirecionar para a home
      this.$router.replace("/login");
    }

    //Converte apenas se ja não converteu antes
    var jaConverteu = this.verificarJaConverteuGoogleAnalytics(idSeguro);
    if (!jaConverteu) {
      var converteu = this.conversaoGoogleAnalytics(
        idSeguro,
        this.seguro.plano,
        this.seguro.valorPlano
      );
      if (converteu) {
        this.registrarConversaoLocalStorage(idSeguro);
      }
    }
  },
  methods: {
    redirectIos() {
      return window.open('https://apps.apple.com/br/app/bike-registrada/id1661678422', '_blank');
    },
    redirectPlay() {
      return window.open('https://play.google.com/store/apps/details?id=br.com.seguro.bikeregistrada', '_blank');
    },
    redirectToAppStore() {
      const userAgent = navigator.userAgent || window.opera;

      if (/iPhone|iPad|iPod/i.test(userAgent)) {
        // Dispositivo iOS
        window.open('https://apps.apple.com/br/app/bike-registrada/id1661678422', '_blank');
      } else {
        // Dispositivo Android
        window.open('https://play.google.com/store/apps/details?id=br.com.seguro.bikeregistrada', '_blank');
      }
    },
    whatsapp() {
      window.open(
        "https://api.whatsapp.com/send?phone=556135224521&text=Olá, fiz meu seguro e preciso de mais informações.",
        "_blank"
      );
    },
    verificarJaConverteuGoogleAnalytics(idSeguro) {
      var dados = this.buscarConversoesLocalStorage();

      var possuiConversao = !!dados[idSeguro];
      return possuiConversao;
    },
    buscarConversoesLocalStorage() {
      var dados = {};

      try {
        var conteudo = window.localStorage.getItem("ConGA_Obrig");
        if (conteudo) {
          dados = JSON.parse(conteudo);
        }
      } catch (error) {
        captureError(error);
      }

      return dados;
    },
    registrarConversaoLocalStorage(idSeguro) {
      var dados = this.buscarConversoesLocalStorage();
      dados[idSeguro] = true;

      window.localStorage.setItem("ConGA_Obrig", JSON.stringify(dados));
    },
    conversaoGoogleAnalytics(transactionId, nomeProduto, valor) {
      try {
        if (window["gtag"] && config.ENABLE_MARKETING_TRACKERS) {
          window["gtag"]("event", "purchase", {
            transaction_id: transactionId,
            affiliation: "Seguro Bike Registrada",
            value: valor,
            currency: "BRL",
            tax: 0,
            shipping: 0,
            items: [{ name: nomeProduto, price: valor }],
          });

          window["gtag"]("event", "conversion", {
            transaction_id: transactionId,
            send_to: "AW-954482663/vI0YCL39xJMCEOf_kMcD",
          });

          return true;
        }
      } catch (e) {
        gtmError(e, {
          transaction_id: transactionId,
          affiliation: "Seguro Bike Registrada",
          value: valor,
          currency: "BRL",
          tax: 0,
          shipping: 0,
          name: nomeProduto,
          price: valor,
        });
      }
      return false;
    },

    async popularInformacoes(idSeguro) {
      try {
        if (!idSeguro) {
          return false;
        }

        this.loadingGeral = true;

        var response = await axios.get(`${config.API_URLV2}/seguro/${idSeguro}`);
        this.seguro = response.data;

        this.loadingGeral = false;
        return true;
      } catch (error) {
        captureError(error);

        await Swal.fire({
          title: "Não foi possível buscar seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return false;
      }
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url("https://fonts.googleapis.com/css?family=Open%20Sans:wght@100..900&display=swap");

.line-height {
  line-height: 20px;
  font-weight: 500;
}
.text-center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.bolder {
  font-weight: bolder;
  color: #4FA935;
}

.title-thanks {
  color: #425994
}

.margin {
  margin: 0px 40px 0px 40px;
}

.self-center {
  align-self: center;
}

.continue-btn {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #4FA935;
  border-radius: 8px;
  background: #4FA935;
  color: #fff;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.ghost {
  text-align: center;
  padding: 10px 15px 10px 15px;
  border: 1px solid transparent;
  border-radius: 8px;
  background: #FFFFFF;
  color: #78849E;
  text-wrap: nowrap;
}

.bold {
  font-family: "Inter";
  font-weight: 700;
}

.underline {
  color: #4FA935 !important;
  text-decoration: underline;
}

.gray {
  color: #78849E;
}

.bg {
  background-image: url("/img/obrigado-checkout.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 30%;
  background-color: #F9F9F9;
  font-family: Inter !important;
  min-height: 100%;
}


@media (max-width: 768px) {
  .desktop {
    display: none !important
  }

  .continue-btn {
    width: 100%;
  }

  .ghost {
    width: 100%;
  }

  .margin {
    margin: 0px 20px 0px 20px;
  }

  .bg {
    background-size: 40%;
  }

  .font-small {
    font-size: 14px;
  }

  .f-14 {
    font-size: 14px;
  }

  .continue-btn {
    font-size: 16px;
  }
}

@media (min-width: 769px) {
  .mobile {
    display: none !important
  }
}
</style>
